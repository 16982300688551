export const emptyArray = [];
export const emptyObj: any = {};
export const emptyHandler = (i) => {};
export const emptyNormalizeFn = (i, p) => i;
export const createId = "00000000-0000-0000-0000-000000000000";

export const yesNoData = [
	{ label: "YES", value: "Yes" },
	{ label: "NO", value: "No" },
];

export const trueFalseData = [
	{ label: "Yes", value: true },
	{ label: "No", value: false },
];

export const translatableYesNoData = (t) => {
	return [t("YES"), t("NO")];
};

export const translatableTrueFalseData = (t) => {
	return [
		{ label: t("YES"), value: true },
		{ label: t("NO"), value: false },
	];
};

export const disabledHandler = (e) => {
	e.preventDefault();
};
